<template>
<!-- Start 東亜商事 breadcrumb section -->
<div class="breadcrumb-area breadcrumb-area-info3 main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Our Specification</h4>
                        <h1 class="mt-3">商品仕様</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End 東亜商事 breadcrumb section -->
<section class="container" style="margin-top:50px;margin-bottom:50px">
 <div class="container">
 
        <div class="fec_recruit_body common_body" data-aos="fade-right">
          
          <div class="fec_recruit_content_body">
            <div>
            <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                  <p><span>Product Specification</span></p>
                  <h4>商品仕様</h4>
             </div>
              <div>
                <table class="fec_recruit_table">
                  <tbody>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>品名</div></td>
                      <td><div>フォークリフト用バッテリー</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>電圧(V)・容量(Ah)</div></td>
                      <td><div>48V 320　Ah(1/2C)<br />
                            48V 330Ah(1/2C)<br />
                            48V 370Ah(1/2C)</div>
                    </td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>最大継続放電電流</div></td>
                      <td>
                        <div>
                         280A　25±2℃
                        </div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>最大放電電流</div></td>
                      <td>
                        <div>560A　25±2℃　60s</div>
                      </td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>最大継続充電電流</div></td>
                      <td><div>280A</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>使用可能温度</div></td>
                      <td><div>-30℃～55℃</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>充電可能温度</div></td>
                      <td><div>0℃～55℃</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>放電警告音</div></td>
                      <td><div>25%以下　
                        <br />＊変更可能です</div></td>
                    </tr>
                    <tr data-aos="fade-right">
                      <td class="fec_recruit_title_td"><div>充電器仕様</div></td>
                      <td><div>48V 150A　～　三相200V30Aコンセント<br />
                      48V 300A　～　三相200V60Aコンセント<br />
                     ＊いずれも電源供給側が容量不足の場合、充電器で出力を調整して使用することができます
                     </div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </div>

    <!-- /.block-title text-center -->
    <!--
    <div class="row high-gutters">
        <div class="col-lg-4 wow fadeInLeft" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-name: fadeInLeft;" 
             data-aos="fade-right" :data-aos-duration="500" v-for="(item,index) in list" v-bind:key="index">
            <div class="pricing-one__single">
               <a :href="'/ServiceDetail/'+item.link">
                  <div class="pricing-one__icon">
                      <img :src="item.img" alt="">
                  </div>
                  <ul class="pricing-one__list list-unstyled">
                      <li>{{item.title}}</li>
                      <li>{{item.subtitle}}</li>
                  </ul>
               </a>
            </div>
        </div>
    </div>
    -->
    <!-- /.row -->
</section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute } from "vue-router";
export default {
  name: "Service",
  components:{ BreadCrumb },
  setup() {
    const route = useRoute();
    const list = [
        {
          link:'CRM',
          img: '/assets/images/service/4.png',
          title: 'CRMシステム',
          subtitle: '顧客管理・商談管理、マルチチャネル連携など'
        },
        {
          link:'Development',
          img: '/assets/images/service/1.png',
          title: 'システム開発',
          subtitle: '金融・医療・物流'
        },
        {
          link:'AI',
          img: '/assets/images/service/5.png',
          title: '人工知能',
          subtitle: 'AIテクノロジーへの取組'
        },
        {
          link:'Embedded',
          img: '/assets/images/service/2.png',
          title: '組込開発',
          subtitle: '通信・製品・車載'
        },
        {
          link:'DataMigration',
          img: '/assets/images/service/3.png',
          title: 'データ移行',
          subtitle: 'システム・各種デバイス'
        },
        {
          link:'BigData',
          img: '/assets/images/service/6.png',
          title: 'ビッグデータ',
          subtitle: 'ビッグデータの効果的活用'
        }
    ]
    return {list}
  }
}
</script>
