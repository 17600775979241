<template>
  <!-- Start 東亜商事 breadcrumb section -->
<div class="main-banner pattern-layer breadcrumb-area">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>We’re the best</h4>
                        <h1 class="mt-3">会社情報</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 <!-- End 東亜商事 breadcrumb section -->
<section class="contact-info-area pb-120" id="contact-info-area">
   <br/><br/><br/>
      <div class="container">
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-list-alt"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>会社名</h4>
                <p>{{HomeInfo.CompanyName}}{{HomeInfo.Abbreviation}}</p>
            </div>
            <!-- /.contact-info-details -->
        </div>
         <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-laptop"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>会社名(英語表記)</h4>
                <p>{{HomeInfo.CompanyNo}}</p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-building"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>住所</h4>
                <p>
                  〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-phone" style="background-image: linear-gradient(-45deg, rgb(64, 158, 255) 0%, rgb(255 64 197) 100%);"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>代表電話番号</h4>
                <p>
                  <span v-if="HomeInfo.Fax" >TEL. </span><a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a>
                  <span v-if="HomeInfo.Fax" >&emsp;FAX. {{HomeInfo.Fax}}</span>
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-envelope-open" style="background-image: linear-gradient(-45deg, #409eff 0%, rgb(235, 255, 64) 100%)"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>E-mail</h4>
                <p>
                  <a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a>
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>

        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-user-circle"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>代表者名 (CEO)</h4>
                <p>
                  {{HomeInfo.Representative}}<span v-if="HomeInfo.Katakana">（{{HomeInfo.Katakana}}）</span>
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center" v-if="HomeInfo.CTO!=''">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-users"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>取締役</h4>
                <p>
                  {{HomeInfo.CTO}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-jpy"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>資本金</h4>
                <p>
                  {{formatPrice(this.HomeInfo.Capital)}}万円
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-calendar-check-o"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>設立</h4>
                <p>
                  {{HomeInfo.CreateDate}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center"  v-if="HomeInfo.Employees!='' && HomeInfo.Employees > 0">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-address-card"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>従業員(人数)</h4>
                <p>
                  {{HomeInfo.Employees}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center"  v-if="HomeInfo.Banks!=''">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-university"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>主要取引銀行</h4>
                <p>
                  {{HomeInfo.Banks}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center" v-if="HomeInfo.YearSell!=''">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-line-chart"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>売上</h4>
                <p>
                  {{HomeInfo.YearSell}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
      </div>
</section>
</template>

<style>
#contact-info-area h4{
    line-height: 50px;
}
#contact-info-area .contact-info-icon i {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    color: #f8f9fa;
    border-radius: 50%;
    margin-right: 30px;
    position: relative;
    box-shadow: 0px 20px 30px 0px #ee3520;
    top: 5px;
    background-image: linear-gradient(90deg, #ee3528 0%, #ee3528 51%, #ee3520 100%)!important;
}
#contact-info-area .align-items-center {
    align-items: center!important;
    margin-bottom: 20px;
}
#contact-info-area a {
    font-family: "Open Sans", sans-serif;
    color: #ee3528;
    font-size: 16px;
}
#contact-info-area p {
    font-size: 16px;
    color: #ee3528;
}
</style>
<script>
import {ref} from "vue";
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "About",
  components:{ BreadCrumb },
  setup() {
      const HomeInfo = ref({})
      HomeInfo.value =   {
            Tel: '090-6656-0831',
            Fax: '',
            PostCode: '289-1124',
            Email: 'info@to-ashoji.com',
            Address: '千葉県八街市山田台762',
            Building: '',
            CompanyName: '東亜商事株式会社',
            CompanyNo: 'EAST ASIA COMMERCE INC.',
            Abbreviation: '（略称：Toa）',
            Representative: '劉  俊',
            Katakana: 'リュウ    シュン',
            Capital: '530',
            Banks: '三菱UFJ銀行 千葉支店',
            CreateDate: '2015年05月',
            YearSell: '',
            HakenNo: '',
            CTO: '',
            MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6672.419574498866!2d140.28212594201133!3d35.578858110738224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60229414f8c94ee1%3A0x447386b93d211bb9!2z44CSMjg5LTExMjQg5Y2D6JGJ55yM5YWr6KGX5biC5bGx55Sw5Y-w77yX77yW77yS!5e0!3m2!1sja!2sjp!4v1682167814983!5m2!1sja!2sjp'
        }
     return {HomeInfo}
  },
  methods: {
    formatPrice(value) {
        return (value||'1000').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
