<template>
    <!-- Start 東亜商事 breadcrumb section -->
  <div class="breadcrumb-area breadcrumb-area-info3 main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Price</h4>
                        <h1 class="mt-3">価格表</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  <section class="auninfo_contact cantact_v2 section_padding" style="margin-top:50px">
    <div class="container">
 
        <div class="fec_recruit_body common_body" data-aos="fade-right">
          
          <div class="fec_recruit_content_body">
            <div>
            <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                  <p><span>Forklift Battery Price List</span></p>
                  <h4>フォークリフトバッテリー価格表</h4>
             </div>
              <div>
                <p data-aos="fade-right" :data-aos-duration="500">フォークリフトメーカー問わず最大荷重(トン)ごとに共通価格です。</p>
                <br/>
                 <h4 data-aos="fade-right" :data-aos-duration="500"><span>リーチ 1.0～2t (48V)</span></h4>
                 <div class="row" data-aos="fade-right" :data-aos-duration="500">
                        <div class="col-lg-12">
                          <table class="fec_recruit_table">
                            <tbody>
                              <tr data-aos="fade-right">
                                <td rowspan="3" style="width:220px;height:auto"><img src="/assets/images/product1.png"/></td>
                                <td class="fec_recruit_title_td"><div>電圧（V）</div></td>
                                <td><div>48V</div></td>
                              </tr>
                              <tr data-aos="fade-right">
                                <td class="fec_recruit_title_td"><div>容量（Ah）</div></td>
                                <td><div>320Ah</div></td>
                              </tr>
                              <tr data-aos="fade-right">
                                <td class="fec_recruit_title_td"><div>価　　格</div></td>
                                <td><div>レンタル月額　 58,000円<br>譲渡までの月数（ 44 月）
                                </div></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                 </div>
                  <div class="tc" data-aos="fade-right" :data-aos-duration="500">     <br/>     <br/>
                    <a href="/contact" class="octf-btn octf-btn-secondary octf-btn-icon home_img_right" tabindex="-1"><span>&emsp;注文する&emsp;<i class="flaticon-right-arrow-1"></i></span></a>
                    <br/>     <br/> <br/>
                  </div>
                   <h4 data-aos="fade-right" :data-aos-duration="500"><span>リーチ 1.0～2t (48V)</span></h4>
                  <div class="row" data-aos="fade-right" :data-aos-duration="500">
                        <div class="col-lg-12">
                          <table class="fec_recruit_table">
                            <tbody>
                              <tr data-aos="fade-right">
                                <td rowspan="3" style="width:220px;height:auto"><img src="/assets/images/product2.png"/></td>
                                <td class="fec_recruit_title_td"><div>電圧（V）</div></td>
                                <td><div>48V</div></td>
                              </tr>
                              <tr data-aos="fade-right">
                                <td class="fec_recruit_title_td"><div>容量（Ah）</div></td>
                                <td><div>330Ah</div></td>
                              </tr>
                              <tr data-aos="fade-right">
                                <td class="fec_recruit_title_td"><div>価　　格</div></td>
                                <td><div>レンタル月額 　76,500円<br>譲渡までの月数（ 46 月）
                                </div></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                 </div>
                  <div class="tc" data-aos="fade-right" :data-aos-duration="500">     <br/>     <br/>
                    <a href="/contact" class="octf-btn octf-btn-secondary octf-btn-icon home_img_right" tabindex="-1"><span>&emsp;注文する&emsp;<i class="flaticon-right-arrow-1"></i></span></a>
                    <br/>     <br/><br/>
                  </div>
                      <h4 data-aos="fade-right" :data-aos-duration="500"><span>リーチ 1.5～2.5t</span></h4>
                       <div class="row" data-aos="fade-right" :data-aos-duration="500">
                        <div class="col-lg-12">
                          <table class="fec_recruit_table">
                            <tbody>
                              <tr data-aos="fade-right">
                                <td rowspan="3" style="width:220px;height:auto"><img src="/assets/images/product3.png"/></td>
                                <td class="fec_recruit_title_td"><div>電圧（V）</div></td>
                                <td><div>48V</div></td>
                              </tr>
                              <tr data-aos="fade-right">
                                <td class="fec_recruit_title_td"><div>容量（Ah）</div></td>
                                <td><div>370Ah</div></td>
                              </tr>
                              <tr data-aos="fade-right">
                                <td class="fec_recruit_title_td"><div>価　　格</div></td>
                                <td><div>レンタル月額 　97,000円<br>譲渡までの月数（ 46 月）
                                </div></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                 </div>
                  <div class="tc" data-aos="fade-right" :data-aos-duration="500">     <br/>     <br/>
                    <a href="/contact" class="octf-btn octf-btn-secondary octf-btn-icon home_img_right" tabindex="-1"><span>&emsp;注文する&emsp;<i class="flaticon-right-arrow-1"></i></span></a>
                    <br/>     <br/><br/>
                  </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
        </div>
      </div>
  </section>
  <!-- End reinfo_contact section -->
</template>

<script>
import {onMounted, ref} from "vue";
import MailFrom from "../components/MailFrom";
import BreadCrumb from "../components/BreadCrumb";

export default {
  name: "Recruit",
  components:{ MailFrom,BreadCrumb},
  data() {
    return {
      active: 0
    }
  },
  setup(){
    const currentTab = ref('MailFrom')
    onMounted(() => {
      document.title = '採用情報 - 東亜商事';
    })
    return {currentTab}
  },
}
</script>