<template>

<a href="#" id="scroll-top" class="back-to-top-btn"><i class="bi bi-arrow-up"></i></a>

<header>
<nav>
	<div class="header-menu-area agency-header">
		<div class="container-fluid">
			<div class="row align-items-center">
				<div class="col-xxl-2 col-xl-2 col-lg-2 col-sm-6 col-6 order-0 order-lg-0">
					<div class="logo text-left">
						<a href="/"><img src="/assets/images/footer-logo.png" alt=""></a>
					</div>
				</div>
				<div class="col-xxl-7 col-xl-6 col-lg-7 col-sm-1 col-1 order-2 order-lg-1">
					<a href="javascript:void(0)" class="hidden-lg hamburger">
						<span class="h-top"></span>
						<span class="h-middle"></span>
						<span class="h-bottom"></span>
					</a>
					<nav class="main-nav">
						<div class="logo mobile-ham-logo d-lg-none d-block text-left">
							<a href="/"><img src="/assets/images/footer-logo.png" alt=""></a>
						</div>
						<ul>
							<li>
								<a href="/" :class="{'active':pathType==='home'}">ホーム</a>
							</li>
							<li>
                                <a href="/about" :class="{'active':pathType==='about'}">会社情報</a>
                            </li>
							<li>
								<a href="/business" :class="{'active':pathType==='business'}">事業内容</a>
							</li>
							<li>
								<a href="/specification" :class="{'active':pathType==='specification'}">商品仕様</a>
							</li>
							<li><a href="/price" :class="{'active':pathType==='price'}">価格表</a></li>
							<li><a href="/access" :class="{'active':pathType==='access'}">アクセス</a></li>
							<li><a href="/contact" :class="{'active':pathType==='contact'}">お問い合わせ</a></li>
						</ul>
					</nav>
				</div>
				<div class="col-xxl-3 col-xl-4 col-lg-3 col-sm-5 col-5 order-1 order-lg-2">
					<div class="menu-btn-wrap">
						<div class="contact-info menu-contact-info d-flex align-items-center">
                     <div class="contact-info-icon">
                        <img src="/assets/images/icon/phone.png" alt="" style="filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);">
                     </div>
                     <div class="contact-info-details">
                        <h5>Contact Us</h5>
						<a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a>
                     </div>
                  </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</nav>
<!-- Hero Area -->

<div class="hero-slide-wrap owl-carousel" id="hero-slide">
	<div class="hero-slide-item hero-slide-item-agency position-relative overflow-hidden">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="hero-content position-relative text-center">
						<h2  style="line-height:45px;" class="mt-1 mt-lg-0">バッテリーメーカーと関係を築いて<br>ベストな価格を実現します。<br/>またお客様のニーズをメーカーにフィードバックすることで<br />商品の改良も日々行っています。</h2>
						<div class="hero-link mt-4 mt-lg-5 d-flex justify-content-center">
							<a class="common-btn" href="/contact">Contact Us<i class="bi bi-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-layer" style="background-image: url(/assets/images/hero/hero-agency-n.png);"></div>

		<!-- <div class="image-layer" style="background-image: url(/assets/images/hero/hero-agency-2.png);"></div>-->
		<img class="hero-agency-sp-1 position-absolute" src="/assets/images/shape/hero-agency-side-left.png" alt="" style="filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);">
		<img class="hero-agency-sp-2 position-absolute" src="/assets/images/shape/hero-agency-side-right.png" alt="" style="filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);">
		<img class="hero-agency-sp-3 position-absolute" src="/assets/images/shape/hero-agency-top-right.png" alt="" style="filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);">
	</div>
	<div class="hero-slide-item position-relative overflow-hidden">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="hero-content position-relative text-center">
						<h2  style="line-height:45px;" class="mt-1 mt-lg-0">バッテリーメーカーと関係を築いて<br>ベストな価格を実現します。<br/>またお客様のニーズをメーカーにフィードバックすることで<br />商品の改良も日々行っています。</h2>
						<div class="hero-link mt-4 mt-lg-5 d-flex justify-content-center">
							<a class="common-btn" href="/specification">Our Service <i class="bi bi-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-layer" style="background-image: url(/assets/images/hero/hero-agency.png);"></div>
		
		<img class="hero-agency-sp-1 position-absolute" src="/assets/images/shape/hero-agency-side-left.png" alt="" style="filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);">
		<img class="hero-agency-sp-2 position-absolute" src="/assets/images/shape/hero-agency-side-right.png" alt="" style="filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);">
		<img class="hero-agency-sp-3 position-absolute" src="/assets/images/shape/hero-agency-top-right.png" alt="" style="filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg) brightness(95%) contrast(112%);">
	</div>
</div>
<!-- Hero Area End -->
</header>
</template>
<style>

.video-wrapper {
  height: 100vh;
  position: relative;
}

.background { 
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; 
  object-fit: cover; 
  opacity: 0.6;
}
.owl-nav,.owl-prev,.owl-next {
    display: none!important;
}
</style>

<script>
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute} from "vue-router"
export default {
  name: "Header",
  setup() { 
    const route = useRoute();
    const pathType = ref('')
    const ww = Window.innerWidth;
    watch(
      () => route.meta.pathType,
      newPathType => {
        pathType.value = newPathType;
		if(newPathType=="home") {
		  document.getElementById('hero-slide').style.display='block';
		} else {
			 document.getElementById('hero-slide').style.display='none';
		}
      }
    )
    onMounted(()=>{
       pathType.value = route.meta.pathType || location.pathname.substring(1);
       if(window.innerWidth < 900) {
        //   $('#'+ pathType.value  + '_current').addClass('current');
       }
    })
    return {pathType,ww}
  }
}
</script>