<template>
  <div class="breadcrumb-area breadcrumb-area-info1 main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Business</h4>
                        <h1 class="mt-3">事業内容</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 <section class="auninfo_contact cantact_v2 section_padding margin-top-about">
    <div class="container">
        <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
            <p><span>フォークリフト用バッテリーリース事業</span></p>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="about-one__content">
                    <div class="about-one__box-wrapper">
                        <div class="about-one__box">
                             <p>バッテリーフォークリフトのバッテリーは、エンジンのように核となる大事なものです。<br/>
                                    大変高価であり易々とは交換できません。<br/>
                                    東亜商事では長年使用してきたバッテリーメーカーの中から、<br/>
                                    能力とコストパフォーマンスの優れたバッテリーを御提供します。<br/><br/><br/>
                            </p>
                        </div>
                        
                         <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
                            <p><span>東亜商事のバッテリー</span></p>
                        </div>
                        <!-- /.about-one__box -->
                        <div class="about-one__box">
                           <img src="/assets/images/business.png"/>
                        </div>
                    </div>
                    <!-- /.about-one__box-wrapper -->
                </div>
            <!-- /.col-lg-6 -->
        </div>  </div>
        <br/>
        <br/>
    </div>
 </section>
</template>

<script>
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Business",
  components:{ BreadCrumb },
  setup() {}
}
</script>

<style scoped>

</style>
