import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
let HomeInfo = {
    Tel: '090-6656-0831',
    Fax: '',
    PostCode: '289-1124',
    Email: 'info@to-ashoji.com',
    Address: '千葉県八街市山田台762',
    Building: '',
    CompanyName: '東亜商事株式会社',
    CompanyNo: 'EAST ASIA COMMERCE INC.',
    Abbreviation: '（略称：Toa）',
    Representative: '劉  俊',
    Katakana: 'リュウ    シュン',
    Capital: '530',
    Banks: '三菱UFJ銀行 千葉支店',
    CreateDate: '2015年05月',
    YearSell: '',
    HakenNo: '',
    CTO: '',
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6672.419574498866!2d140.28212594201133!3d35.578858110738224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60229414f8c94ee1%3A0x447386b93d211bb9!2z44CSMjg5LTExMjQg5Y2D6JGJ55yM5YWr6KGX5biC5bGx55Sw5Y-w77yX77yW77yS!5e0!3m2!1sja!2sjp!4v1682167814983!5m2!1sja!2sjp'
}
const app = createApp(App)
    // load 
$.ajax({
    type: "GET",
    url: "/api/company.php",
    timeout: 100,
    dataType: "json",
    success: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
    error: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
});
app.config.globalProperties.HomeInfo = res
app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')