<template>
<div class="professional-service-area service-area-design-agency pt-120 pb-96">
	<div class="container">
		<div class="row">
			<div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12">
				<div class="section-title white-2 text-center">
					<h4>What We Are Expert At</h4>
					<h1 class="mt-3">東亜商事</h1>
				</div>
			</div>
		</div>
		<div class="row mt-60">
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-1.png" alt="">
						<span>01</span>
					</div>
					<h3>バッテリー</h3>
					<p class="mt-4">いずれも電源供給側が容量不足の場合、充電器で出力を調整して使用することができます。</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-2.png" alt="">
						<span>02</span>
					</div>
					<h3>バッテリー</h3>
					<p class="mt-4">バッテリーメーカーと関係を築いてベストな価格を実現します。&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-3.png" alt="">
						<span>03</span>
					</div>
					<h3>企業目指</h3>
					<p class="mt-4">新たなニーズに的確に応えうる生産・流通・消費の要となる企業を目指しております。</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-4.png" alt="">
						<span>04</span>
					</div>
					<h3>東亜商事で働く</h3>
					<p class="mt-4">お客様との絆を深め、挑戦しつづけること、挑戦・努力をしつづける事が重要であると考えています。</p>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="business-video-area">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8 col-12">
				<div class="business-video-title text-center" data-aos="fade-right" :data-aos-duration="500">
                    <h2 style="color: #fff;line-height: 60px;">社員の幸せを考え続ける企業として</h2>
					<h3 style="color: #fff;line-height: 40px;font-size: 20px;">「人」にこだわり続けることが企業の成長に繋がる<br>
                            今後の東亜商事の成長を握るのは「人財」であると考えており、<br>
                            人材は財産であり「宝」であり、そして次代を担う経営資源となります。<br>
                            企業の成長性、将来性の両面から見ても、人にこだわり続ける事が更なる成長の鍵に繋がります。<br>
                            東亜商事では社員の幸せを考え、常に問い続けています。社員の幸せ、成長なくして企業の成長はないと考えています。<br>
                            その為、各種規程制度、休暇・休業制度、健康管理他、社員利用保養所や社内イベント等、充実した福利厚生制度を設けております。
　　　　　　　　　　　　</h3>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="blog-area pt-120 pb-96">
	<div class="container">
		<div class="row">
			<div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-12">
				<div class="section-title text-center">
					<h4>Our Blog Posts</h4>
					<h1 class="mt-3">お知らせ</h1>
				</div>
			</div>
		</div>
		<div class="row mt-60">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="blog-wrap overflow-hidden">
                        <div class="blog-img overflow-hidden">
                            <a href="/"><img class="w-100" src="assets/images/blog/blog-1.png" alt=""></a>
                        </div>
                        <div class="blog-content">
                            <p class="blog-meta"><a href="/">Toa News</a> <span>|</span> 2023-4-3</p>
                            <h3 class="mt-3 mb-3"><a href="/">業務拡大のため、本店千葉県に移転しました</a></h3>
                            <div class="blog-btn">
                                <a class="icon-btn" href="/"><i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="blog-wrap overflow-hidden">
                        <div class="blog-img overflow-hidden">
                            <a href="/"><img class="w-100" src="assets/images/blog/blog-2.png" alt=""></a>
                        </div>
                        <div class="blog-content">
                            <p class="blog-meta"><a href="/">Toa News</a> <span>|</span> 2022-4-20</p>
                            <h3 class="mt-3 mb-3"><a href="/">展示大商談会を開催しました</a></h3>
                            <div class="blog-btn">
                                <a class="icon-btn" href="/"><i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="blog-wrap overflow-hidden">
                        <div class="blog-img overflow-hidden">
                            <a href="/"><img class="w-100" src="assets/images/blog/blog-3.png" alt=""></a>
                        </div>
                        <div class="blog-content">
                            <p class="blog-meta"><a href="/">Toa News</a> <span>|</span> 2015-5-18</p>
                            <h3 class="mt-3 mb-3"><a href="/">東亜商事設立し、資本金500万円です</a></h3>
                            <div class="blog-btn">
                                <a class="icon-btn" href="/"><i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		<div class="row mt-60">
			<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-right" :data-aos-duration="500" v-for="(item, index) in newsList" :key="index">
				<div class="blog-wrap overflow-hidden">
					<div class="blog-img overflow-hidden">
						<router-link :to="`/news/${item.id}`"><img class="w-100" :src="item.img" alt="" style="width:370px;height:220px;"></router-link>
					</div>
					<div class="blog-content">
						<p class="blog-meta"><router-link :to="`/news/${item.id}`">Toa News</router-link> <span>|</span> {{ item.time }}</p>
						<h3 class="mt-3 mb-3"><router-link :to="`/news/${item.id}`">{{ item.title }}</router-link></h3>
						<div class="blog-btn">
							<router-link :to="`/news/${item.id}`" class="icon-btn"><i class="bi bi-arrow-right"></i></router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>


<script>
// banner設定
function slickDots (){
    if($('.slick-dots').length) {
        let slickList = $('.slick-dots')[0].childNodes;
        if (slickList.length) {
            var point = 1;
            $('#bannercroll').css('background-image','')
            for (var i=1; i <= slickList.length; i++) {
                point = i;
                const className = slickList[i-1].className;
                if(i==1 && className) { 
                    $('#bannercroll').css('background-image','')
                    $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
                else if(i==2 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%)');
                    // -webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%);
                } else if(i==3 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(47, 18, 137, 0.7) 0%, #4f1eab 100%)');
                } else {
                    //   $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
            }
        }
    }
}
window.setInterval(slickDots, 100);
import { onMounted, ref,onUnmounted } from "vue";
export default {
  name: "Home",
  setup() {
   
    const newsList = ref([]);
    onMounted(() => {
      resize();
    //   initSlick();
    });
    function resize() {
      const height = $(window).height();
      $('#bannercroll').css('height',height);
    }
    window.addEventListener('resize', resize)
    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>
