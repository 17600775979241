<template>
<div class="breadcrumb-area breadcrumb-area-info main-banner">
    <div class="pattern-layer" style="background-image: url(/assets/images/shape/shape-25.png);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>Management Philosophy</h4>
                        <h1 class="mt-3">経営理念</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <!-- End reinfo_breadcrumb section -->
  <section class="blog-grid">
   <div class="container">  
      <div class="block-title text-center" data-aos="fade-right" :data-aos-duration="500">
           <p style="width:100%" class="color-1"><span>Management Philosophy</span></p>
      </div>
      <div class="row post_content">
          <h2>信用・信頼はビジネス成り立ちの基礎</h2>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">“有言実行”、お客様からのご満足とご信頼を得る事を最も重視し、頂いた仕事は必ずやり遂げ、常に高品質で低コストのサービスを提供する事により、お客様との厚い信頼関係を構築して参ります。</p>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
          <h2>お客様と社員が共に発展する会社を目指します</h2>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">常にお客様のニーズに答えられるように努力し、信頼されるビジネスパートナを目指して、Win-Win関係を構築し「お客様と共に発展・発達する会社」を目指して参ります。社員に対しても、充実した教育と活躍の場を提供し、より高いステージを目指して参ります。</p>
          <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
          <h2>常に改善を怠らず、高効率な実行体制を構築して参ります</h2>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">厳しい競争環境において、目標管理を徹底し、常にマネジメントの仕組みの改善を行い、標準化管理により高効率な実行体制を構築し、お客様に競争力があるサービスを提供して参ります。</p>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500"></p>
          <h2>お客様の価値創造に貢献して参ります</h2>
           <p style="width:100%"  data-aos="fade-right" :data-aos-duration="500">常に新しい技術に挑戦しマスターする事で、新規事業を開拓しお客様の期待に応えられる様な課題解決能力を身につけ、新たな価値を創造してお客様に提供して参ります。</p>
      </div>
  </div>
  <!-- /.container -->
</section>
</template>

<script>
import {onMounted,ref} from "vue"
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "Philosophy",
  components:{ BreadCrumb },
  setup() {
    
  }
}
</script>
